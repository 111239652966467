import React from 'react';
import './bannerImage.scss';

interface BannerImageInterface {
    img: string;
    titulo: string;
    subtitulo: string;
    copyBoton: string;
    redireccion: string;
}

const BannerImage = (props: BannerImageInterface) => {
    const { img, titulo, subtitulo, copyBoton, redireccion } = props;

    return (
        <div className="a-bannerImage">
            <div className="a-bannerImage__container">
                <div className="a-bannerImage__container__text">
                    <span>{titulo}</span>
                    <p>{subtitulo}</p>
                    <a
                        href={redireccion}
                        rel="noreferrer follow"
                        target="_self"
                    >
                        {copyBoton}
                    </a>
                </div>
                <div className="a-bannerImage__container__image">
                    <img src={img} alt="" />
                </div>
            </div>
        </div>
    );
};

export default BannerImage;
