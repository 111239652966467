import React from 'react';
import Slider from 'react-slick';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import BannerImage from '../../atoms/bannerImage/bannerImage';
import './bannerSlider.scss';

const BannerSlider = ({ slides }: any) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        initialSlide: 0,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    dots: false,
                    centerMode: false,
                },
            },
            {
                breakpoint: 330,
                settings: {
                    dots: true,
                    centerMode: false,
                },
            },
        ],
    };

    return (
        <div className="o-banner__slider">
            <Slider {...settings} className="o-banner__slider__slider">
                {slides.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <BannerImage
                                img={item.img}
                                titulo={item.titulo}
                                subtitulo={item.subtitulo}
                                copyBoton={item.copyBoton}
                                redireccion={item.redireccion}
                            />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default BannerSlider;
