import React, { useEffect, useState } from "react";
import "./cardTasas.scss";
import Button from "../../atoms/button/button";
import Slider from "react-slick";
import { useMediaQuery } from "@material-ui/core";

export interface ICardTasasProps {
   title: string;
   description: React.ReactNode;
   tituloBoton: string;
   evento: string;
   cardsList: [
      {
         title: string;
         ctaTitle: string;
         descriptionCard: React.ReactNode;
         descriptionList: any;
      }
   ];
}

const CardTasas = ({ title, description, cardsList, tituloBoton, evento }) => {
   const [openStates, setOpenStates] = useState<boolean[]>([]);
   const isSmallScreen = useMediaQuery("(max-width:600px)");
   const index = 0;

   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

   const toggleDiv = (index: number) => {
      const newOpenStates = [...openStates];
      newOpenStates[index] = !newOpenStates[index];
      setOpenStates(newOpenStates);
   };
   useEffect(() => {
      const openStates = cardsList.map(() => false);
      setOpenStates(openStates);
   }, []);

   const separateString = (description: string) => {
      const data = description.split(":");
      return data;
   };

   const dividerDescriptionCardText = (
      descriptionCard: string,
      description: string,
      index: number
   ) => {
      const descriptionPositionCard = descriptionCard.split("|");
      const dataTitle = description[0];
      const titleList = dataTitle[0].split("|");
      const dataText = description[1];
      const info = dataText[0].split("|");
      return (
         <div className="o-priceInformation__card__box__body">
            <div className="o-priceInformation__card__box__body__content">
               <div className="o-priceInformation__card__box__body__content__title">
                  {descriptionPositionCard[0]}
               </div>
               <div className="o-priceInformation__card__box__body__content__tasa">
                  {descriptionPositionCard[1]}
               </div>
               <div className="o-priceInformation__card__box__body__content__subsidio">
                  {descriptionPositionCard[2]}
               </div>
            </div>
            <div
               className="o-priceInformation__card__box__slider"
               onClick={() => toggleDiv(index)}
            >
               <div
                  className={`o-priceInformation__card__box__slider__title ${
                     openStates[index] ? "active" : ""
                  }`}
                  onClick={() => toggleDiv(index)}
               >
                  {titleList[0]}
                  <span
                     className={`o-priceInformation__card__box__slider-${
                        openStates[index] ? "text1" : "text2"
                     }`}
                  >
                     {openStates[index] ? "-" : "+"}
                  </span>
               </div>
               {openStates[index] && (
                  <div className="o-priceInformation__card__box__slider__content">
                     <ul className="o-priceInformation__card__box__slider__content active">
                        {info.map((item, idx) => {
                           const infoItem = separateString(item);
                           return (
                              <li key={idx}>
                                 {infoItem.length > 1 && (
                                    <p style={{ marginBottom: 4 }}>
                                       <span
                                          style={{
                                             display: "flex",
                                             justifyContent: "space-between",
                                          }}
                                       >
                                          <span>
                                             <span style={{ color: "#EE2B7B" }}>
                                                <svg
                                                   width="8"
                                                   height="8"
                                                   viewBox="0 0 24 24"
                                                   fill="none"
                                                   xmlns="http://www.w3.org/2000/svg"
                                                >
                                                   <path
                                                      d="M12 2V22M2 12H22M4.93 4.93L19.07 19.07M4.93 19.07L19.07 4.93"
                                                      stroke="#EE2B7B"
                                                      strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                   />
                                                </svg>
                                             </span>
                                             <span />
                                             {infoItem[0].includes(
                                                "<Plena>"
                                             ) ? (
                                                <>
                                                   {infoItem[0].replace(
                                                      "<Plena>",
                                                      "Plena"
                                                   )}
                                                   <div className="o-priceInformation__card__box__slider__content__tooltip">
                                                      <span className="o-priceInformation__card__box__slider__content__tooltip-icon">
                                                         <svg
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                         >
                                                            <circle
                                                               cx="12"
                                                               cy="12"
                                                               r="10"
                                                               fill="#EE2B7B"
                                                            />
                                                            <text
                                                               x="12"
                                                               y="17"
                                                               textAnchor="middle"
                                                               fill="white"
                                                               fontSize="16"
                                                               fontWeight="bold"
                                                            >
                                                               ?
                                                            </text>
                                                         </svg>
                                                      </span>
                                                      <span className="o-priceInformation__card__box__slider__content__tooltip__text">
                                                         <span
                                                            style={{
                                                               color: "#EE2B7B",
                                                               fontSize: "12px",
                                                            }}
                                                         >
                                                            <strong>
                                                               {titleList[0]}
                                                            </strong>
                                                         </span>
                                                         <br />
                                                         <span
                                                            style={{
                                                               fontSize: "12px",
                                                            }}
                                                         >
                                                            {titleList[1]}
                                                         </span>
                                                      </span>
                                                   </div>
                                                </>
                                             ) : (
                                                infoItem[0]
                                             )}
                                          </span>
                                          <span>{infoItem[1]}</span>
                                       </span>
                                    </p>
                                 )}
                              </li>
                           );
                        })}
                     </ul>
                  </div>
               )}
            </div>
         </div>
      );
   };

   const titleCard = (data: { ctaTitle: string; title: string }) => {
      const tooltipData = data.ctaTitle.split("|");
      return (
         <div className="o-priceInformation__card__box__title">
            {data.title}
            <div className="o-priceInformation__card__box__title__tooltip">
               <span className="o-priceInformation__card__box__title__tooltip-icon">
                  <svg
                     width="15"
                     height="15"
                     viewBox="0 0 24 24"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <circle cx="12" cy="12" r="10" fill="#EE2B7B" />
                     <text
                        x="12"
                        y="17"
                        textAnchor="middle"
                        fill="white"
                        fontSize="16"
                        fontWeight="bold"
                     >
                        ?
                     </text>
                  </svg>
               </span>
               <span className="o-priceInformation__card__box__title__tooltip__text">
                  <p style={{ color: "#EE2B7B", fontSize: "12px" }}>
                     <strong>{tooltipData[0]}</strong>
                  </p>
                  <p />
                  <p style={{ fontSize: "12px" }}>{tooltipData[1]}</p>
               </span>
            </div>
         </div>
      );
   };

   return (
      <div className="o-priceInformation__card__container">
         <h2 className="o-priceInformation__card__container__title">{title}</h2>
         <h3 className="o-priceInformation__card__container__text">
            {description}
         </h3>
         {isSmallScreen ? (
            <div key={index} className="o-priceInformation__card__box">
               <Slider {...settings}>
                  {cardsList.map((card, index) => (
                     <div
                        key={index}
                        className="o-priceInformation__card__box__container"
                     >
                        {titleCard(card)}
                        {dividerDescriptionCardText(
                           card.descriptionCard,
                           card.descriptionList,
                           index
                        )}
                     </div>
                  ))}
               </Slider>
            </div>
         ) : (
            <div className="o-priceInformation__card__box">
               {cardsList.map((card, index) => (
                  <div
                     key={index}
                     className="o-priceInformation__card__box__container"
                  >
                     {titleCard(card)}
                     {dividerDescriptionCardText(
                        card.descriptionCard,
                        card.descriptionList,
                        index
                     )}
                  </div>
               ))}
            </div>
         )}
         <Button
            id="buttonDescription"
            type="link"
            classname={"secondary"}
            text={tituloBoton}
            isDisabled={false}
            href={evento}
         />
      </div>
   );
};

export default CardTasas;
