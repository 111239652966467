function bannerSliderAdapter(data: any) {
   console.log(data)
    return {
        slides:
            data?.referenciaPlantillaContenido?.map((slide) => ({
               img: slide.imagenPrincipal[0].file.url,
               titulo: slide.tituloBanner,
               subtitulo: slide.tituloDescripcion,
               copyBoton: slide.tituloBoton,
               redireccion: slide.redireccionBoton,
            })) ?? [],
    };
}

export default bannerSliderAdapter;
