import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ICardTasasProps } from "../CardTasas";
import { BLOCKS } from "@contentful/rich-text-types";


function cardsTasasAdapter(data: any): ICardTasasProps {
  return {
    title: data.tituloGeneral ?? '',
    description: documentToReactComponents(data.descripcion.json) ?? '',
    tituloBoton: data.tituloBoton ?? '',
    evento: data.evento ?? '',
    cardsList:
      data?.referenciaPlantillaContenido?.map((card) => ({
        title: card.tituloBanner,
        ctaTitle: card.tituloDescripcion,
        descriptionCard: card.tipoTitulo,
        descriptionList: card.descripcion.json ? documentToReactComponents(card.descripcion.json,
          { renderNode: { [BLOCKS.PARAGRAPH]: (node: any, children: React.ReactNode) => children }}
        ) : [],
      })) ?? [],
  };
};
export default cardsTasasAdapter;